// Portugues
export const locale = {
  lang: 'pt',
  data: {
    TRANSLATOR: {
      SELECT: 'Escolha seu idioma',
    },
    MENU: {
      NEW: 'novo',
      ACTIONS: 'Ações',
      CREATE_POST: 'Criar nova postagem',
      PAGES: 'Páginas',
      FEATURES: 'Ferramentas',
      APPS: 'Apps',
      DASHBOARD: 'Dashboard',
    },
    AUTH: {
      GENERAL: {
        OR: 'Ou',
        SUBMIT_BUTTON: 'Enviar',
        NO_ACCOUNT: 'Não possui uma conta?',
        SIGNUP_BUTTON: 'Registrar-se',
        FORGOT_BUTTON: 'Esqueci minha senha',
        BACK_BUTTON: 'Voltar',
        PRIVACY: 'Privacidade',
        LEGAL: 'Legal',
        CONTACT: 'Contato',
      },
      LOGIN: {
        TITLE: 'Login',
        BUTTON: 'Entrar',
      },
      FORGOT: {
        TITLE: 'Esqueceu a senha?',
        DESC: 'Informe seu email para recuperar sua senha',
        SUCCESS: 'Sua conta foi recuperada com sucesso.'
      },
      REGISTER: {
        TITLE: 'Registrar-se',
        DESC: 'Informe seus dados para criar uma conta',
        SUCCESS: 'Sua conta foi criada com sucesso.'
      },
      INPUT: {
        EMAIL: 'Email',
        FULLNAME: 'Nome completo',
        PASSWORD: 'Senha',
        CONFIRM_PASSWORD: 'Confirmação de senha',
        USERNAME: 'Username'
      },
      VALIDATION: {
        INVALID: '{{name}} é inválido',
        REQUIRED: '{{name}} é obrigatório',
        MIN_LENGTH: '{{name}} tamanho mínimo é {{min}}',
        AGREEMENT_REQUIRED: 'Aceitação dos termos é obrigatória',
        NOT_FOUND: '{{name}} não foi encontrado',
        INVALID_LOGIN: 'Informações de login estão incorretas',
        REQUIRED_FIELD: 'Campo obrigatório',
        MIN_LENGTH_FIELD: 'Tamanho mínimo do campo:',
        MAX_LENGTH_FIELD: 'Tamanho máximo do campo:',
        INVALID_FIELD: 'Campo é inválido',
      }
    },
    ECOMMERCE: {
      COMMON: {
        SELECTED_RECORDS_COUNT: 'Selected records count: ',
        ALL: 'All',
        SUSPENDED: 'Suspended',
        ACTIVE: 'Active',
        FILTER: 'Filter',
        BY_STATUS: 'by Status',
        BY_TYPE: 'by Type',
        BUSINESS: 'Business',
        INDIVIDUAL: 'Individual',
        SEARCH: 'Search',
        IN_ALL_FIELDS: 'in all fields'
      },
      ECOMMERCE: 'eCommerce',
      CUSTOMERS: {
        CUSTOMERS: 'Customers',
        CUSTOMERS_LIST: 'Customers list',
        NEW_CUSTOMER: 'New Customer',
        DELETE_CUSTOMER_SIMPLE: {
          TITLE: 'Customer Delete',
          DESCRIPTION: 'Are you sure to permanently delete this customer?',
          WAIT_DESCRIPTION: 'Customer is deleting...',
          MESSAGE: 'Customer has been deleted'
        },
        DELETE_CUSTOMER_MULTY: {
          TITLE: 'Customers Delete',
          DESCRIPTION: 'Are you sure to permanently delete selected customers?',
          WAIT_DESCRIPTION: 'Customers are deleting...',
          MESSAGE: 'Selected customers have been deleted'
        },
        UPDATE_STATUS: {
          TITLE: 'Status has been updated for selected customers',
          MESSAGE: 'Selected customers status have successfully been updated'
        },
        EDIT: {
          UPDATE_MESSAGE: 'Customer has been updated',
          ADD_MESSAGE: 'Customer has been created'
        }
      }
    }
  }
};
