import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthModel } from '../../modules/auth/_models/auth.model';
import { Constants } from '../constantes/constants-model';
import { Igreja } from '../model/igreja.model';

@Injectable()
export class AuthorizationInterception implements HttpInterceptor {
    private authLocalStorageToken = `${environment.appVersion}-${environment.USERDATA_KEY}`;

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        const accountInfo: AuthModel = this.getAuthFromLocalStorage();

        if (accountInfo) {
            const token = accountInfo && accountInfo.accessToken;
            const igreja = this.getPrimaryChurchLocalStorage();

            if (!igreja) {
                request = request.clone({
                    setHeaders: {
                        Authorization: 'Bearer ' + token
                    }
                });
            } else {
                request = request.clone({
                    setHeaders: {
                        Authorization: 'Bearer ' + token
                    },
                    setParams: {
                        igrejaId: igreja.id
                    }
                });
            }
        }
        return next.handle(request);
    }

    private getAuthFromLocalStorage(): AuthModel {
        try {
            const authData = JSON.parse(
                localStorage.getItem(this.authLocalStorageToken)
            );
            return authData;
        } catch (error) {
            console.error(error);
            return undefined;
        }
    }

    private getPrimaryChurchLocalStorage(): Igreja {
        try {
            const igreja = JSON.parse(localStorage.getItem(Constants.STORAGE.IGREJA_PRINCIPAL));
            return igreja ? igreja : null;
        } catch (error) {
            console.error(error);
            return undefined;
        }
    }
}
