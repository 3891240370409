const baseUrl = `https://logos.eti.br`;
const apiUrl = `https://api.logos.eti.br`;

export const environment = {
    production: false,
    appVersion: '1.10.0',
    USERDATA_KEY: 'authf649fc9a5f55',
    apiUrl: `${apiUrl}`,
    wsSecurity: `${baseUrl}/ws-security`,
    wsI12: `${apiUrl}/ws-i12`,
    wsGanhar: `${baseUrl}/ws-ganhar`,
    wsConsolidar: `${baseUrl}/ws-consolidar`,
    wsDiscipular: `${baseUrl}/ws-discipular`,
    wsEnviar: `${baseUrl}/ws-enviar`
};
