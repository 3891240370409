import { environment } from 'src/environments/environment';

export const Constants: any = {
    STORAGE: {
        IGREJA_PRINCIPAL: 'i12-primary-church'
    },
    wsSecurity: {
        login: `${environment.wsSecurity}/login`
    },
    wsI12: {
        indicador: `${environment.wsI12}/indicadores`,
    },
    wsGanhar: {
        visitas: `${environment.wsGanhar}/visitas`,
        tiposConversao: `${environment.wsGanhar}/visitas/tipos-conversao`,
        rotaDaVida: `${environment.wsGanhar}/rota_da_vida`,
        registroContatos: `${environment.wsGanhar}/rota_da_vida/contatos`
    },
    wsConsolidar: {
        univida: {
            licoes: `${environment.wsConsolidar}/univida/licoes`,
            frequencia: `${environment.wsConsolidar}/univida/frequencia`,
            aulas: `${environment.wsConsolidar}/univida/aulas`,
            alunos: `${environment.wsConsolidar}/univida/alunos`,
            turmas: `${environment.wsConsolidar}/univida/turmas`,
        },
    },
    wsDiscipular: {
        ecd: {
            licoes: `${environment.wsDiscipular}/ecd/licoes`,
            frequencia: `${environment.wsDiscipular}/ecd/frequencia`,
            aulas: `${environment.wsDiscipular}/ecd/aulas`,
            alunos: `${environment.wsDiscipular}/ecd/alunos`,
            turmas: `${environment.wsDiscipular}/ecd/turmas`,
            modulos: `${environment.wsDiscipular}/ecd/modulos`,
        },
    },
};
